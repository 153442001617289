<template>
    <mobile-admin-collapse-tab title="Local" :badge="getBadgeOrNull('local')">
        <p v-if="!reservations.length">Aucune demande pour l'instant</p>
        <div v-for="(reservation, index) in reservations" :key="'reservation_' + reservation.id">
            <hr v-if="index"/>
            <p class="text-center">
                <user-name :user="reservation.utilisateur"/>
            </p>
            <p>
                {{ formatDates(reservation) }} <br/>
            </p>
            <p class="text-center">
                <b-button variant="success" pill @click="confirmReservation(reservation, true)">
                    <font-awesome-icon icon="check"/>
                </b-button>
                <b-button variant="danger" pill @click="confirmReservation(reservation, false)">
                    <font-awesome-icon icon="times"/>
                </b-button>
            </p>
        </div>
    </mobile-admin-collapse-tab>
</template>

<script>
    import {mapGetters} from 'vuex';

    import {apiPath}            from '@/util/http';
    import alert                from '@/util/alert';
    import demande              from '@/util/demande';
    import {updateBadgesBureau} from '@/util/badge';
    import reservationsMixin    from '@/mixin/reservationsMixin';


    const UserName               = () => import('@/components/UserName');
    const CommentValidationModal = () => import('@/components/modals/CommentValidationModal');
    const MobileAdminCollapseTab = () => import('./MobileAdminCollapseTab');

    export default {
        name: "MobileAdminLocalTab",
        components: {UserName, MobileAdminCollapseTab},
        mixins: [reservationsMixin],
        data: () => ({
            reservations: []
        }),
        computed: {
            ...mapGetters({getBadgeOrNull: ['badge/getBadgeOrNull']})
        },
        methods: {
            ...demande,
            loadData() {
                alert.loading()
                    .then(() => this.axios.get(apiPath('list_waiting_reservations_admin')))
                    .then(response => this.reservations = demande.momentDates(response.data))
                    .catch(() => this.$toaster.error('Impossible de récupérer la liste des réservations'))
                    .finally(alert.stopLoading);
            },
            confirmReservation(reservation, valid) {
                this.$store.dispatch('modal/create', {
                    component: CommentValidationModal,
                    props: {
                        title: (valid ? 'Validation' : 'Refus') + " de la réservation",
                        commentaireUtilisateur: reservation.commentaireUtilisateur,
                        callback: (commentaire, notifyDiscord) => this.axios
                            .post(
                                apiPath('confirm_reservation_admin', {reservation: reservation.id}),
                                {valid: valid, commentaire: commentaire, notify_discord: notifyDiscord}
                            )
                            .then(() => {
                                this.$toaster.success('Réservation ' + (valid ? 'validée' : 'refusée') + ' avec succès');
                                this.loadData();
                                updateBadgesBureau();
                            })
                            .catch(() => this.$toaster.error("Impossible de modifier la réservation"))
                    }
                })
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>

<style scoped>
    em {
        text-decoration: underline;
    }
</style>